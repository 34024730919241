   // Città Sede Legale (RO)
   shelf.createAutoComplete3(
    '/searchRoCity/',
    'searchForRoCity',
    'searchForRoCityDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForRoCityDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.name);
            listItem.on('click', function() {
                $('#searchForRoCity').val(ele.name);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);

// Provincia Sede Legale (RO)
shelf.createAutoComplete3(
    '/searchRoProvince/',
    'searchForRoProvince',
    'searchForRoProvinceDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForRoProvinceDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.code);
            listItem.on('click', function() {
                $('#searchForRoProvince').val(ele.code);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);

// CAP Sede Legale (RO)
shelf.createAutoComplete3(
    '/searchRoCap/',
    'searchForRoCap',
    'searchForRoCapDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForRoCapDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.cap);
            listItem.on('click', function() {
                $('#searchForRoCap').val(ele.cap);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);

// Ripeti lo stesso per la Sede Operativa (PL)

// Città Sede Operativa (PL)
shelf.createAutoComplete3(
    '/searchPlCity/',
    'searchForPlCity',
    'searchForPlCityDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForPlCityDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.name);
            listItem.on('click', function() {
                $('#searchForPlCity').val(ele.name);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);

// Provincia Sede Operativa (PL)
shelf.createAutoComplete3(
    '/searchPlProvince/',
    'searchForPlProvince',
    'searchForPlProvinceDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForPlProvinceDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.code);
            listItem.on('click', function() {
                $('#searchForPlProvince').val(ele.code);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);

// CAP Sede Operativa (PL)
shelf.createAutoComplete3(
    '/searchPlCap/',
    'searchForPlCap',
    'searchForPlCapDropdown',
    2,
    function(data) {
        var autocompleteDropdown = $('#searchForPlCapDropdown');
        autocompleteDropdown.empty();
        data.forEach((ele) => {
            var listItem = $('<li></li>').addClass('dropdown-item').text(ele.cap);
            listItem.on('click', function() {
                $('#searchForPlCap').val(ele.cap);
                autocompleteDropdown.removeClass('show');
            });
            autocompleteDropdown.append(listItem);
        });
    }
);
